import Vue from "vue";
import Router from "vue-router";
import Login from "@/views/dashboard/login.vue";
import NotFound from "@/views/dashboard/pages/NotFound/404.vue";
import forgotPassword from "@/views/dashboard/forgotPassword.vue";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    // login
    {
      name: "Login",
      path: "/login",
      component: Login
    },
    // forgot password
    {
      name: "Forgot Password",
      path: "/forgotPassword",
      component: forgotPassword
    },
    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),
      children: [
        // Dashboard
        {
          name: "Dashboard",
          path: "/",
          component: () => import("@/views/dashboard/Dashboard")
        },
        // Pages
        {
          name: "Roles",
          path: "roles",
          component: () => import("@/views/dashboard/pages/Roles/AllRoles"),
          props: true
        },
        {
          name: "Add Role",
          path: "role/create",
          component: () =>
            import("@/views/dashboard/pages/Roles/AddEditRole.vue"),
          props: true
        },
        {
          name: "Edit Role",
          path: "role/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Roles/AddEditRole.vue"),
          props: true
        },
        {
          name: "View Role",
          path: "role/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Roles/AddEditRole.vue"),
          props: true
        },
        {
          name: "Admin Staff",
          path: "/users/staff",
          component: () =>
            import("@/views/dashboard/pages/Users/AdminUsers.vue"),
          props: true
        },
        {
          name: "Customers",
          path: "/users/customers",
          component: () =>
            import("@/views/dashboard/pages/Users/CustomerUsers.vue"),
          props: true
        },
        {
          name: "Drivers",
          path: "/users/drivers",
          component: () =>
            import("@/views/dashboard/pages/Users/DriverUsers.vue"),
          props: true
        },
        {
          name: "Add Admin Staff",
          path: "user/admin/create",
          component: () =>
            import("@/views/dashboard/pages/Users/AddEditAdminUser.vue"),
          props: true
        },
        {
          name: "Edit Admin Staff",
          path: "user/admin/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Users/AddEditAdminUser.vue"),
          props: true
        },
        {
          name: "View Admin Staff",
          path: "user/admin/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Users/AddEditAdminUser.vue"),
          props: true
        },
        {
          name: "Add Customer",
          path: "user/customer/create",
          component: () =>
            import("@/views/dashboard/pages/Users/AddEditCustomerUser.vue"),
          props: true
        },
        {
          name: "Edit Customer",
          path: "user/customer/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Users/AddEditCustomerUser.vue"),
          props: true
        },
        {
          name: "View Customer",
          path: "user/customer/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Users/AddEditCustomerUser.vue"),
          props: true
        },
        {
          name: "Add Driver",
          path: "user/driver/create",
          component: () =>
            import("@/views/dashboard/pages/Users/AddEditDriverUser.vue"),
          props: true
        },
        {
          name: "Edit Driver",
          path: "user/driver/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Users/AddEditDriverUser.vue"),
          props: true
        },
        {
          name: "View Driver",
          path: "user/driver/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Users/AddEditDriverUser.vue"),
          props: true
        },
        {
          name: "Categories",
          path: "categories",
          component: () =>
            import("@/views/dashboard/pages/Categories/AllCategories.vue"),
          props: true
        },
        {
          name: "Edit Category",
          path: "category/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Categories/AddEditCategory.vue"),
          props: true
        },
        {
          name: "View Category",
          path: "category/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Categories/AddEditCategory.vue"),
          props: true
        },
        {
          name: "Add Category",
          path: "category/create",
          component: () =>
            import("@/views/dashboard/pages/Categories/AddEditCategory.vue"),
          props: true
        },
        {
          name: "SubCategories",
          path: "sub-categories",
          component: () =>
            import(
              "@/views/dashboard/pages/SubCategories/AllSubCategories.vue"
            ),
          props: true
        },
        {
          name: "Add Sub Category",
          path: "sub-category/create",
          component: () =>
            import(
              "@/views/dashboard/pages/SubCategories/AddEditSubCategory.vue"
            ),
          props: true
        },
        {
          name: "Edit Sub Category",
          path: "sub-category/edit/:id",
          component: () =>
            import(
              "@/views/dashboard/pages/SubCategories/AddEditSubCategory.vue"
            ),
          props: true
        },
        {
          name: "View Sub Category",
          path: "sub-category/view/:id",
          component: () =>
            import(
              "@/views/dashboard/pages/SubCategories/AddEditSubCategory.vue"
            ),
          props: true
        },
        {
          name: "Products",
          path: "products",
          component: () =>
            import("@/views/dashboard/pages/Products/AllProducts.vue"),
          props: true
        },
        {
          name: "Edit Product",
          path: "product/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Products/AddEditProduct.vue"),
          props: true
        },
        {
          name: "View Product",
          path: "product/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Products/AddEditProduct.vue"),
          props: true
        },
        {
          name: "Add Product",
          path: "product/create",
          component: () =>
            import("@/views/dashboard/pages/Products/AddEditProduct.vue"),
          props: true
        },
        {
          name: "SalesOrder",
          path: "sales_channels",
          component: () =>
            import("@/views/dashboard/pages/SalesChannel/AllSalesorder.vue"),
          props: true
        },
        {
          name: "Damaged Products",
          path: "damaged-products",
          component: () =>
            import("@/views/dashboard/pages/DamagedProducts/AllDamaged.vue"),
          props: true
        },
        {
          name: "Add Damaged",
          path: "damaged-products/create",
          component: () =>
            import(
              "@/views/dashboard/pages/DamagedProducts/AddEditDamaged.vue"
            ),
          props: true
        },
        {
          name: "Edit Sales",
          path: "sales_channels/edit/:id",
          component: () =>
            import(
              "@/views/dashboard/pages/SalesChannel/AddEditSalesorder.vue"
            ),
          props: true
        },
        {
          name: "View Sales",
          path: "sales_channels/view/:id",
          component: () =>
            import(
              "@/views/dashboard/pages/SalesChannel/AddEditSalesorder.vue"
            ),
          props: true
        },
        {
          name: "Add Sales",
          path: "sales_channels/create",
          component: () =>
            import(
              "@/views/dashboard/pages/SalesChannel/AddEditSalesorder.vue"
            ),
          props: true
        },
        {
          name: "Stores",
          path: "stores",
          component: () =>
            import("@/views/dashboard/pages/Stores/AllStores.vue"),
          props: true
        },
        {
          name: "Edit Store",
          path: "store/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Stores/AddEditStore.vue"),
          props: true
        },
        {
          name: "View Store",
          path: "store/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Stores/AddEditStore.vue"),
          props: true
        },
        {
          name: "Add Store",
          path: "store/create",
          component: () =>
            import("@/views/dashboard/pages/Stores/AddEditStore.vue"),
          props: true
        },
        {
          name: "View Store Product",
          path: "store/product/:id",
          component: () =>
            import("@/views/dashboard/pages/Stores/viewStoreProduct.vue"),
          props: true
        },
        {
          name: "Vans",
          path: "vans",
          component: () => import("@/views/dashboard/pages/Vans/AllVans.vue"),
          props: true
        },
        {
          name: "Edit Van",
          path: "van/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Vans/AddEditVan.vue"),
          props: true
        },
        {
          name: "View Van",
          path: "van/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Vans/AddEditVan.vue"),
          props: true
        },
        {
          name: "Add Van",
          path: "van/create",
          component: () =>
            import("@/views/dashboard/pages/Vans/AddEditVan.vue"),
          props: true
        },
        {
          name: "View Van Product",
          path: "van/product/:id",
          component: () =>
            import("@/views/dashboard/pages/Vans/viewVanProduct.vue"),
          props: true
        },
        {
          name: "Offers",
          path: "offers",
          component: () =>
            import("@/views/dashboard/pages/Offers/AllOffers.vue"),
          props: true
        },
        {
          name: "Edit Offer",
          path: "offer/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Offers/AddEditOffer.vue"),
          props: true
        },
        {
          name: "View Offer",
          path: "offer/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Offers/AddEditOffer.vue"),
          props: true
        },
        {
          name: "Add Offer",
          path: "offer/create",
          component: () =>
            import("@/views/dashboard/pages/Offers/AddEditOffer.vue"),
          props: true
        },
        {
          name: "Purchases",
          path: "purchases",
          component: () =>
            import("@/views/dashboard/pages/Purchases/AllPurchases.vue"),
          props: true
        },
        {
          name: "Add Purchase",
          path: "purchase/create",
          component: () =>
            import("@/views/dashboard/pages/Purchases/AddEditPurchase.vue"),
          props: true
        },
        {
          name: "Edit Purchase",
          path: "purchase/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Purchases/AddEditPurchase.vue"),
          props: true
        },
        {
          name: "View Purchase",
          path: "purchase/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Purchases/AddEditPurchase.vue"),
          props: true
        },
        {
          name: "User Info",
          path: "user-info",
          component: () =>
            import("@/views/dashboard/pages/UserInfo/AllUserInfo.vue"),
          props: true
        },
        {
          name: "Add User Info",
          path: "user-info/create",
          component: () =>
            import("@/views/dashboard/pages/UserInfo/AddEditUserInfo.vue"),
          props: true
        },
        {
          name: "Edit User Info",
          path: "user-info/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/UserInfo/AddEditUserInfo.vue"),
          props: true
        },
        {
          name: "View User Info",
          path: "user-info/view/:id",
          component: () =>
            import("@/views/dashboard/pages/UserInfo/AddEditUserInfo.vue"),
          props: true
        },
        {
          name: "Orders",
          path: "orders",
          component: () =>
            import("@/views/dashboard/pages/Orders/AllOrders.vue"),
          props: true
        },
        {
          name: "View Order",
          path: "orders/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Orders/AddEditOrders.vue"),
          props: true
        },
        {
          name: "Banner",
          path: "banner",
          component: () =>
            import("@/views/dashboard/pages/Banner/AllBanner.vue"),
          props: true
        },
        {
          name: "Add Banner",
          path: "banner/create",
          component: () =>
            import("@/views/dashboard/pages/Banner/AddEditBanner.vue"),
          props: true
        },
        {
          name: "Edit Banner",
          path: "banner/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Banner/AddEditBanner.vue"),
          props: true
        },
        {
          name: "View Banner",
          path: "banner/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Banner/AddEditBanner.vue"),
          props: true
        },
        {
          name: "Recipes",
          path: "recipes",
          component: () =>
            import("@/views/dashboard/pages/Recipes/AllRecipes.vue"),
          props: true
        },
        {
          name: "Add Recipe",
          path: "recipe/create",
          component: () =>
            import("@/views/dashboard/pages/Recipes/AddEditRecipes.vue"),
          props: true
        },
        {
          name: "Edit Recipe",
          path: "recipe/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Recipes/AddEditRecipes.vue"),
          props: true
        },
        {
          name: "View Recipe",
          path: "recipe/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Recipes/AddEditRecipes.vue"),
          props: true
        },
        {
          name: "Promocode",
          path: "promocode",
          component: () =>
            import("@/views/dashboard/pages/Promocode/AllPromocode.vue"),
          props: true
        },
        {
          name: "Add Promocode",
          path: "promocode/create",
          component: () =>
            import("@/views/dashboard/pages/Promocode/AddEditPromocode.vue"),
          props: true
        },
        {
          name: "Edit Promocode",
          path: "promocode/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Promocode/AddEditPromocode.vue"),
          props: true
        },
        {
          name: "View Promocode",
          path: "promocode/view/:id",
          component: () =>
            import("@/views/dashboard/pages/Promocode/AddEditPromocode.vue"),
          props: true
        },
        {
          name: "Predefined Address",
          path: "predefined-address",
          component: () =>
            import(
              "@/views/dashboard/pages/PredefinedAddress/AllPredefinedAddress.vue"
            ),
          props: true
        },
        {
          name: "Add Predefined Address",
          path: "predefined-address/create",
          component: () =>
            import(
              "@/views/dashboard/pages/PredefinedAddress/AddEditPredefinedAddress.vue"
            ),
          props: true
        },
        {
          name: "Edit Predefined Address",
          path: "predefined-address/edit/:id",
          component: () =>
            import(
              "@/views/dashboard/pages/PredefinedAddress/AddEditPredefinedAddress.vue"
            ),
          props: true
        },
        {
          name: "View Predefined Address",
          path: "predefined-address/view/:id",
          component: () =>
            import(
              "@/views/dashboard/pages/PredefinedAddress/AddEditPredefinedAddress.vue"
            ),
          props: true
        },
        {
          name: "All Drivers Earning Payout",
          path: "driver-payout",
          component: () =>
            import(
              "@/views/dashboard/pages/DriverEarningPayout/AllDriverEarning.vue"
            ),
          props: true
        },
        {
          name: "View Driver Earning Payout",
          path: "driver-payout/view/:id",
          component: () =>
            import(
              "@/views/dashboard/pages/DriverEarningPayout/AddEditDriverEarning.vue"
            ),
          props: true
        },
        {
          name: "Product Request By Customer",
          path: "product-request-customer",
          component: () =>
            import(
              "@/views/dashboard/pages/ProductRequestByCustomer/AllProductRequestByCustomer.vue"
            ),
          props: true
        },
        {
          name: "View Product Request",
          path: "product-request-customer/view/:id",
          component: () =>
            import(
              "@/views/dashboard/pages/ProductRequestByCustomer/AddEditProductRequestByCustomer.vue"
            ),
          props: true
        },
        {
          name: "Reports",
          path: "reports",
          component: () =>
            import("@/views/dashboard/pages/Reports/AllReports.vue"),
          props: true
        },
        {
          name: "Delivery Charges",
          path: "general-settings",
          component: () =>
            import("@/views/dashboard/pages/Settings/GeneralSettings.vue"),
          props: true
        },
        {
          name: "Register Customer Email",
          path: "register-customer-email",
          component: () =>
            import(
              "@/views/dashboard/pages/Settings/RegisterCustomerEmail.vue"
            ),
          props: true
        },
        {
          name: "Reset Password Email",
          path: "reset-password-email",
          component: () =>
            import("@/views/dashboard/pages/Settings/ResetPasswordEmail.vue"),
          props: true
        },
        {
          name: "Register Driver Email",
          path: "register-driver-email",
          component: () =>
            import("@/views/dashboard/pages/Settings/RegisterDriverEmail.vue"),
          props: true
        },
        {
          name: "Order Purchase Email",
          path: "order-purchase-email",
          component: () =>
            import("@/views/dashboard/pages/Settings/OrderPurchaseEmail.vue"),
          props: true
        },
        {
          name: "Order Purchase Email",
          path: "low-inventory-management",
          component: () =>
            import("@/views/dashboard/pages/Settings/LowVanInventory.vue"),
          props: true
        },
        {
          name: "Driver Cancel Order",
          path: "driver-cancel-order",
          component: () =>
            import(
              "@/views/dashboard/pages/DriverCancelOrder/AllDriverCancelOrder.vue"
            ),
          props: true
        },
        {
          name: "View Driver Cancel Order",
          path: "driver-cancel-order/view/:id",
          component: () =>
            import(
              "@/views/dashboard/pages/DriverCancelOrder/AddEditDriverCancelOrder.vue"
            ),
          props: true
        },
        {
          name: "userLocation",
          path: "user-location",
          component: () => import("@/views/dashboard/pages/UserLocation.vue"),
          props: true
        },
        {
          name: "Push Notification",
          path: "push-notification",
          component: () =>
            import(
              "@/views/dashboard/pages/pushNotification/pushNotification.vue"
            ),
          props: true
        },
        {
          name: "Tickets",
          path: "tickets",
          component: () =>
            import("@/views/dashboard/pages/Tickets/AllTickets.vue"),
          props: true
        },
        {
          name: "Edit Ticket",
          path: "tickets/edit/:id",
          component: () =>
            import("@/views/dashboard/pages/Tickets/EditTicket.vue"),
          props: true
        },
        // theme routes
        {
          name: "User Profile",
          path: "pages/user",
          component: () => import("@/views/dashboard/pages/UserProfile")
        },
        {
          name: "Notifications",
          path: "components/notifications",
          component: () => import("@/views/dashboard/component/Notifications")
        },
        {
          name: "Icons",
          path: "components/icons",
          component: () => import("@/views/dashboard/component/Icons")
        },
        {
          name: "Typography",
          path: "components/typography",
          component: () => import("@/views/dashboard/component/Typography")
        },
        // Tables
        {
          name: "Regular Tables",
          path: "tables/regular-tables",
          component: () => import("@/views/dashboard/tables/RegularTables")
        },
        // Maps
        {
          name: "Google Maps",
          path: "maps/google-maps",
          component: () => import("@/views/dashboard/maps/GoogleMaps")
        },
        // if router not found then redirect to the login page
        {
          name: "Page Not Found",
          path: "/*",
          component: NotFound
        }
      ]
    }
  ]
});
